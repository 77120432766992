#map {
    width: 100%;
    height: 600px;
    left: 0px;
}

.range-container {
    position: relative;
    height: 50px;
    margin: 25px 25px;
}

.slider-track {
    position: absolute;
    top: 50%;
    height: 4px;
    width: 100%;
    background: #ddd;
    transform: translateY(-50%);
}

.slider-range {
    position: absolute;
    top: 50%;
    height: 4px;
    background: #4CAF50;
    transform: translateY(-50%);
}

input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    background: none;
    pointer-events: none;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 18px;
    width: 18px;
    background: #4CAF50;
    border-radius: 50%;
    border: 2px solid white;
    cursor: pointer;
    pointer-events: auto;
    box-shadow: 0 1px 3px rgba(0,0,0,0.3);
}

input[type="range"]::-moz-range-thumb {
    height: 18px;
    width: 18px;
    background: #4CAF50;
    border-radius: 50%;
    border: 2px solid white;
    cursor: pointer;
    pointer-events: auto;
    box-shadow: 0 1px 3px rgba(0,0,0,0.3);
}

.values {
    text-align: center;
    margin-top: 10px;
    font-family: sans-serif;
}

.popup-content {
    width: 100%;
    text-align: center;
}

.popup-content-items {
    list-style-type: none;
    padding: 0;
}

.popup-content > ul > li {
  padding: 2px;
  margin-bottom: 2px;
  border-bottom: 1px solid #ddd;
}